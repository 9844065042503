import React from 'react'
import '../../styles.css'
import './plans.css'
import geo02 from '../../images/geometric-bg-02.svg'
import check from '../../images/checkmark-p.svg'

const Plans = (props) => (

    <div className="plans__wrapper" ref={props.planScroll}>
        <div className="plans__geo-bg">
            <img alt="Vector Background" src={geo02} className="geo-bg-02" />
        </div>
        <h1 className="plans__h1">
            Our plans
        </h1>
        <div className="plans__plans-row">
            <div className="plans__plan-01">
                <div className="plans__plan-01-top">
                </div>
                <h2 className="plans__plan-header">
                    Starter
                </h2>
               {props.starterMonthly === true ?
                <h3 className="plans__plan-price-01">
                    $29 / mo*
                </h3>
                :
                <h3 className="plans__plan-price-01">
                    $24 / mo*
                </h3>
                }
                <div className="plans__controller-row">
                    <p className={props.starterMonthly === true ? "plans__plan-select--active" : "plans__plan-select"}>
                        Monthly
                    </p>
                    <div onClick={props.toggleStarterPlan} className={props.starterMonthly === true ? "plans__plan-controller" : "plans__plan-controller--active"}>
                        <div className={props.starterMonthly === true ? "plans__plan-button" : "plans__plan-button plans__plan-button--active"}>
                        </div>
                </div>
                    <p className={props.starterMonthly === true ? "plans__plan-select" : "plans__plan-select--active"}>
                        Annual
                    </p>
                </div>
                <div className="plans__features-list"> 
                    <div className="plans__feature-list-item-row">
                        <img alt="Checkmark" src={check} className="plans__feature-bullet" />
                        <div className="plans__features-list-item">
                            Unlimited Influencers
                        </div>
                    </div> 
                    <div className="plans__feature-list-item-row">
                        <img alt="Checkmark" src={check} className="plans__feature-bullet" />
                        <div className="plans__features-list-item">
                            Unlimited Campaigns
                        </div>
                    </div>
                    <div className="plans__feature-list-item-row">
                        <img alt="Checkmark" src={check} className="plans__feature-bullet" />
                        <div className="plans__features-list-item">
                            300 Orders Per Month
                        </div>
                    </div>
                    <div className="plans__feature-list-item-row">
                        <img alt="Checkmark" src={check} className="plans__feature-bullet" />
                        <div className="plans__features-list-item">
                            10,000 Unique Visitors Per Month
                        </div>
                    </div>
                    <div className="plans__feature-list-item-row">
                        <img alt="Checkmark" src={check} className="plans__feature-bullet" />
                        <div className="plans__features-list-item">
                            PayPal Mass Payments
                        </div>
                    </div>
                    <div className="plans__feature-list-item-row">
                        <img alt="Checkmark" src={check} className="plans__feature-bullet" />
                        <div className="plans__features-list-item">
                            Live Chat
                        </div>
                    </div>
                </div>
                <p className={props.starterMonthly === false ? "plans__plan-disclaimer" : "plans__plan-disclaimer plans__plan-disclaimer--hidden"}>
                    *Price reflects yearly plan
                </p>
            </div>

            <div className="plans__plan-02">
                <div className="plans__plan-02-top">
                </div>
                <h2 className="plans__plan-header">
                    Pro
                </h2>
                {props.proMonthly === true ?
                <h3 className="plans__plan-price-02">
                    $99 / mo*
                </h3>
                :
                <h3 className="plans__plan-price-02">
                    $82 / mo*
                </h3>
                }
                <div className="plans__controller-row">
                    <p className={props.proMonthly === true ? "plans__plan-select--active" : "plans__plan-select"}>
                        Monthly
                    </p>
                    <div onClick={props.toggleProPlan} className={props.proMonthly === true ? "plans__plan-controller" : "plans__plan-controller--active"}>
                        <div className={props.proMonthly === true ? "plans__plan-button" : "plans__plan-button plans__plan-button--active"}>
                        </div>
                    </div>
                    <p className={props.proMonthly === true ? "plans__plan-select" : "plans__plan-select--active"}>
                        Annual
                    </p>
                </div>
                <p className="plans__pro-caption">
                    Everything in Starter, Plus...
                </p>
                <div className="plans__features-list">
                    <div className="plans__feature-list-item-row">
                        <img alt="Checkmark" src={check} className="plans__feature-bullet" />  
                        <div className="plans__features-list-item">
                            1,000 Orders Per Month
                        </div>
                    </div>
                    <div className="plans__feature-list-item-row">
                        <img alt="Checkmark" src={check} className="plans__feature-bullet" />
                        <div className="plans__features-list-item">
                            100,000 Unique Vistors Per Month
                        </div>
                    </div>
                    <div className="plans__feature-list-item-row">
                        <img alt="Checkmark" src={check} className="plans__feature-bullet" />
                        <div className="plans__features-list-item">
                            Featured Placement in Marketplace
                        </div>
                    </div>
                    <div className="plans__feature-list-item-row">
                        <img alt="Checkmark" src={check} className="plans__feature-bullet" />
                        <div className="plans__features-list-item">
                            Phone Support
                        </div>
                    </div>
                    <div className="plans__feature-list-item-row">
                        <img alt="Checkmark" src={check} className="plans__feature-bullet" />
                        <div className="plans__features-list-item">
                            Custom Reports
                        </div>
                    </div>
                    <div className="plans__feature-list-item-row">
                        <img alt="Checkmark" src={check} className="plans__feature-bullet" />
                        <div className="plans__features-list-item">
                            Custom Branding
                        </div>
                    </div>
                    <div className="plans__feature-list-item-row">
                        <img alt="Checkmark" src={check} className="plans__feature-bullet" />
                        <div className="plans__features-list-item">
                            Custom Domain
                        </div>
                    </div>
                </div>
                <p className={props.proMonthly === false ? "plans__plan-disclaimer" : "plans__plan-disclaimer plans__plan-disclaimer--hidden"}>
                    *Price reflects yearly plan
                </p>
            </div>
            {/* <div className="plans__plan-03">
                <div className="plans__plan-03-top">
                </div>
                <h2 className="plans__plan-header">
                    Agency
                </h2>
                <h3 className="plans__plan-price-03">
                    Contact Us
                </h3>
                <p className="plans__pro-caption">
                    Everything in Pro, Plus...
                </p>
                <div className="plans__features-list">
                    <div className="plans__feature-list-item-row">
                        <img alt="Checkmark" src={check} className="plans__feature-bullet" />  
                        <div className="plans__features-list-item">
                            Multiple Stores
                        </div>
                    </div>
                    <div className="plans__feature-list-item-row">
                        <img alt="Checkmark" src={check} className="plans__feature-bullet" />
                        <div className="plans__features-list-item">
                            Custom Branded Reports
                        </div>
                    </div>
                    <div className="plans__feature-list-item-row">
                        <img alt="Checkmark" src={check} className="plans__feature-bullet" />
                        <div className="plans__features-list-item">
                            Handpicked Brand Ambassadors
                        </div>
                    </div>
                    <div className="plans__feature-list-item-row">
                        <img alt="Checkmark" src={check} className="plans__feature-bullet" />
                        <div className="plans__features-list-item">
                            VIP Onboarding for Individual Stores
                        </div>
                    </div>
                    <div className="plans__feature-list-item-row">
                        <img alt="Checkmark" src={check} className="plans__feature-bullet" />
                        <div className="plans__features-list-item">
                            Custom Integrations
                        </div>
                    </div>
                    <div className="plans__feature-list-item-row">
                        <img alt="Checkmark" src={check} className="plans__feature-bullet" />
                        <div className="plans__features-list-item">
                            1:1 Consulting
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    </div>
)

export default Plans