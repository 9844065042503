import React from 'react'
import '../../styles.css'
import './trust.css'
import userOne from '../../images/user-01.png'
import userTwo from '../../images/user-02.png'
import userThree from '../../images/user-03.png'
import geo03 from '../../images/geometric-bg-03.svg'

const Trust = () => (
    <div className="trust__wrapper">
        <div className="trust__geo-bg">
            <img alt="Vector Background" src={geo03} className="geo-bg-03" />
        </div>
        <h3 className="trust__header">
            Discover why businesses trust LLAMA.
        </h3>
        {/* <h3 className="trust__header">
            More than 15,000 businesses trust LLAMA.
        </h3> */}
        <h3 className="trust__header">
            See what they have to say.
        </h3>
        <div className="trust__business-row">
            <div className="trust__business">
                <h4 className="trust__business-header">
                    Saving a ton of time
                </h4>
                <p className="trust__business-paragraph">
                    “My marketing team and I have been thoroughly enjoying Llama.
                    Llama has a slew of features that are readily accessible and 
                    easy to use. Collaborating with influencers has never been 
                    easier for us.”
                </p>
                <div className="trust__business-info">
                    <div>
                        <img alt="Joshua P." src={userOne} className="trust__info-image" />
                    </div>
                    <div className="trust__info-col">
                        <h4 className="trust__info-username">
                            Joshua P.
                        </h4>
                    </div>
                </div>
            </div>
            <div className="trust__business">
                <h4 className="trust__business-header">
                    Love the unlimited
                </h4>
                <p className="trust__business-paragraph">
                    “We sell a wide variety of products and wanted to target customers 
                    from all kinds of different niches. Most influencers in these niches 
                    tend to have smaller reaches, so we knew we had to engage with as many 
                    of them as possible. Llama let us do just that. Unlike other apps we 
                    tried, Llama has no limitations on the number of influencers and this 
                    allowed us to surpass our goals." 
                </p>
                <div className="trust__business-info">
                    <div>
                        <img alt="Sarah J." src={userThree} className="trust__info-image" />
                    </div>
                    <div className="trust__info-col">
                        <h4 className="trust__info-username">
                            Sarah J.
                        </h4>
                    </div>
                </div>
            </div>
            <div className="trust__business">
                <h4 className="trust__business-header">
                    So far so good
                </h4>
                <p className="trust__business-paragraph">
                    “I’ve been running my online store for a few years but I was having 
                    trouble getting enough customers to stay afloat. I’d heard about 
                    affiliate marketing and wanted to try it, but I wasn’t sure how 
                    to go about it. Llama’s simplicity and competitive price tag made 
                    it easy to get started and in just a few weeks I had more customers 
                    than I ever thought I would.”
                </p>
                <div className="trust__business-info">
                    <div>
                        <img alt="Sean M." src={userTwo} className="trust__info-image" />
                    </div>
                    <div className="trust__info-col">
                        <h4 className="trust__info-username">
                            Sean M.
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default Trust