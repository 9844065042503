import React from 'react'
import '../../styles.css'
import './features.css'
import featuresShopify from '../../images/features-shop.svg'
import featuresProfiles from '../../images/features-profiles.svg'
import featuresLtv from '../../images/features-ltv.svg'
import featuresImport from '../../images/features-import.svg'
import featuresAmbass from '../../images/features-ambass.svg'
import featuresCommish from '../../images/features-commish.svg'
import jewelry from '../../images/jewelry-logo.svg'
import vida from '../../images/vida-logo.svg'
import vidatea from '../../images/vidatea-logo.svg'
import zura from '../../images/zura-logo.svg'
import paypal from '../../images/paypal-logo.svg'
import shopify from '../../images/shopify-logo.svg'
import slack from '../../images/slack-logo.svg'
import llamabg from '../../images/llama-bg-02.png'
import llamabgm from '../../images/llama-bg-02-m.png'

const Features = (props) => (
    <div ref={props.featureScroll}>
        <div className="features__feature-wrapper">
            <div className="features__feature-row">
                <div className="features__feature">
                        <img alt="Look-Back/Look-Forward Features" src={featuresCommish} className="features__img" />
                        <h3 className="features__feature-title">
                            Look-Back &amp; Look-Forward Windows
                        </h3>
                        <p className="features__feature-desc">
                            Adjust the window of time partners can earn commission on customers they send your way.
                        </p>
                    </div>
                {/* End features__feature */}
                <div className="features__feature">
                    <img alt="Smart Profiles" src={featuresProfiles} className="features__img" />
                    <h3 className="features__feature-title">
                        Smart Ambassador Profiles
                    </h3>
                    <p className="features__feature-desc">
                        Ambassadors search and filter brands based on preferences, making it easier to find you.
                    </p>
                </div>
                {/* End features__feature */}
                <div className="features__feature">
                    <img alt="Long-Term Commissions" src={featuresLtv} className="features__img" />
                    <h3 className="features__feature-title">
                        Long-Term Recurring Commissions
                    </h3>
                    <p className="features__feature-desc">
                        When ambassadors see more reward from your brand, they put more elbow grease into promoting it.
                    </p>
                </div>
                {/* End features__feature */}
                <div className="features__feature">
                    <img alt="Easy Ambassador Import" src={featuresImport} className="features__img" />
                    <h3 className="features__feature-title">
                        Ambassador Import Migration
                    </h3>
                    <p className="features__feature-desc">
                        Transfer your data hassle-free and get on with your day.
                    </p>
                </div>
                {/* End features__feature */}
                <div className="features__feature">
                    <img alt="Unlimited Ambassadors" src={featuresAmbass} className="features__img" />
                    <h3 className="features__feature-title">
                        Unlimited Ambassadors
                    </h3>
                    <p className="features__feature-desc">
                        No influencer cap, no growth limits. Scale your brand to the clouds.
                    </p>
                </div>
                {/* End features__feature */}
                <div className="features__feature">
                    <img alt="Shopify Integration" src={featuresShopify} className="features__img" />
                    <h3 className="features__feature-title">
                        1-Click Shopify Integration
                    </h3>
                    <p className="features__feature-desc">
                        Getting started is easy. Connect your Shopify store with just one click.
                    </p>
                </div>
                {/* End features__feature */}
                
            </div>
            {/* End features__feature-row */}

            <div className="features__partners">
                <h3 className="features__h3">
                    As seen on
                </h3>
                <img alt="Jewelry.yoga" src={jewelry} className="features__jewelry-logo" />
                <img alt="Vida Essentials" src={vida} className="features__vida-logo" />
                <img alt="Vida Tea" src={vidatea} className="features__vidatea-logo" />
                <img alt="Zura" src={zura} className="features__zura-logo" />
            </div>
            {/* End features__partners */}
        </div>

        <div className="features__integ-wrapper">
            <div className="features__integ-flex">
                <div className="features__integration">
                    <h2 className="features__h2">
                        Seamless integration
                    </h2>
                    <p className="features__integration-par">
                        Llama works smoothly with the apps you're already using.
                    </p>
                    <img alt="Paypal Integration" src={paypal} className="features__integration-img" />
                    <p className="features__integration-desc">
                        Using Paypal as a payout platform creates a safety net 
                        for your network, ensuring smooth payment and commissions.
                    </p>
                    <img alt="Shopify Integration" src={shopify} className="features__integration-img" />
                    <p className="features__integration-desc">
                        1-click Shopify installation is the only kind of one-and-done Llama approves of.
                    </p>
                    {/* <img src={slack} className="features__integration-img" />
                    <p className="features__integration-desc">
                        Finding the right ambassadors begins by cultivating a community 
                        of communication. Integrate with slack to segment groups and 
                        broadcast important updates. Communicate with your affiliates. 
                    </p> */}
                </div>
                {/* End features__integration */}
                

                <div className="features__llama-bg-wrapper">
                    <div className="features__llama-bg-ratio">
                        <div className="features__llama-bg-container">
                            <img alt="Vector Circle Background" src={llamabg} className="features__llama-bg-img" />
                            <img alt="Vector Circle Background" src={llamabgm} className="features__llama-bg-m" />
                        </div>
                        {/* End features__llama-bg-container */}

                        {/*<div className="features__llama-bg-container-m">
                            <img src={llamabgm} className="features__llama-bg-m" />
                        </div>*/}

                        <div className="features__llama-bg-text">
                            <div className="features__merchants-onboard">
                                <h1 className="features__total-onboard">
                                    16,000
                                </h1>
                                <h2 className="features__user-onboard">
                                    Merchants Onboard
                                </h2>
                            </div>
                            {/* End features__merchants-onboard */}

                            <div className="features__affiliates-onboard">
                                <h2 className="features__user-onboard">
                                    Affiliates Registered
                                </h2>
                                <h1 className="features__total-onboard">
                                    590,000
                                </h1>
                            </div>
                        </div>
                    </div>
                    {/* End features__affiliates-onboard */}
                    

                </div>
                {/* End features__llama-bg-wrapper */}
            </div>
            {/* End features__integ-flex */}
        </div>
        {/* End features__integ-wrapper */}
    
    {/* End features__feature-wrapper */}
    </div>
)

export default Features