import React from 'react'
import '../../styles.css'
import './footer.css'
import fb from '../../images/fb-logo.svg'
import ig from '../../images/ig-logo.svg'
import twitter from '../../images/twitter-logo.svg'
import youtube from '../../images/youtube-logo.svg'

const Footer = () => (
    <div className="footer__bg">
        <div className="footer__wrapper">
            <div className="footer__footer-row">
                <div className="footer__social-row">
                    <a href="https://www.facebook.com/llama.ap/" target="_blank" rel="noopener noreferrer" className="footer__social-link">
                        <img alt="Facebook" src={fb} className="footer__social-fb" />
                    </a>
                    <a href="https://www.instagram.com/llama.app/" target="_blank" rel="noopener noreferrer" className="footer__social-link">
                        <img alt="Instagram" src={ig} className="footer__social-ig" />
                    </a>
                    <a href="https://twitter.com/Llama_app" target="_blank" rel="noopener noreferrer" className="footer__social-link">
                        <img alt="Twitter" src={twitter} className="footer__social-twitter" />
                    </a>
                    <a href="https://www.youtube.com/channel/UCCkk90JjkpFB_OmbfA5mY9A" target="_blank" rel="noopener noreferrer" className="footer__social-link">
                        <img alt="YouTube" src={youtube} className="footer__social-youtube" />
                    </a>
                </div>
                <div className="footer__links-row">
                    <div className="footer__footer-links">
                        <a href="https://llama.app/llama_tos.html" target="_blank" rel="noopener noreferrer" className="footer__links-link">
                            Terms and Conditions
                        </a>
                        <a href="https://llama.app/llama_privacy.html" target="_blank" rel="noopener noreferrer" className="footer__links-link">
                            Privacy Policy
                        </a>
                        <a href="https://llama.app/llama_dmca.html" target="_blank" rel="noopener noreferrer" className="footer__links-link">
                            DMCA
                        </a>
                    </div>
                </div>
            </div>

            <p className="footer__copyright">
                &copy;2019 LLAMA - All Rights Reserved
            </p>
        </div>
    </div>
)

export default Footer