import React, { Component } from 'react'

import Layout from '../components/layout'
import Hero from '../components/hero/hero'
import Features from '../components/features/features'
import Plans from '../components/plans/plans'
import Video from '../components/video/video'
import Trust from '../components/trust/trust'
import Form from '../components/form/form'
import Footer from '../components/footer/footer'

class IndexPage extends Component {

  constructor(props) {
    super(props)
    this.featureRef = React.createRef()
    this.planRef = React.createRef()
    this.contactRef = React.createRef()
    this.state = {
       starterMonthly: true,
       proMonthly: true,
       isMobile: false,
       isDropdownOpen: false
    };

    this.toggleStarterPlan = this.toggleStarterPlan.bind(this);
    this.toggleProPlan = this.toggleProPlan.bind(this);
    this.toggleMobile = this.toggleMobile.bind(this);
    this.handleDropdown = this.handleDropdown.bind(this);
  }

  scrollToFeature = () => {
    window.scrollTo(0, this.featureRef.current.offsetTop);
    this.setState({ 
      isMobile: !this.state.isMobile 
    });
  }

  scrollToPlans = () => {
    window.scrollTo(0, this.planRef.current.offsetTop);
    this.setState({ 
      isMobile: !this.state.isMobile 
    });
  }

  scrollToContact = () => {
    window.scrollTo(0, this.contactRef.current.offsetTop);
    this.setState({ 
      isMobile: !this.state.isMobile 
    });
  }

  toggleStarterPlan() {
    this.setState({
      starterMonthly: !this.state.starterMonthly
    });
  }

  toggleProPlan() {
    this.setState({
      proMonthly: !this.state.proMonthly
    });
  }

  toggleMobile() {
    this.setState({ 
      isMobile: !this.state.isMobile 
    });
  }

  handleDropdown() {
    this.setState({
      isDropdownOpen: !this.state.isDropdownOpen
    })
  }

  render() {
    return (
      <Layout>
        <Hero
          scrollFunc={this.scrollToFeature}
          scrollFuncPlans={this.scrollToPlans}
          scrollFuncContact={this.scrollToContact}
          isMobile={this.state.isMobile}
          toggleMobile={this.toggleMobile}
          handleDropdown={this.handleDropdown}
          isDropdownOpen={this.state.isDropdownOpen}
        />
        <Features
          featureScroll={this.featureRef}
        />
        <Plans
          planScroll={this.planRef}
          toggleStarterPlan={this.toggleStarterPlan}
          toggleProPlan={this.toggleProPlan}
          starterMonthly={this.state.starterMonthly}
          proMonthly={this.state.proMonthly}
        />
        <Video
          scrollFuncContact={this.scrollToContact}
        />
        <Trust></Trust>
        <Form
          contactScroll={this.contactRef}
        />
        <Footer></Footer>
      </Layout>
    )
  }
}

export default IndexPage

