import React from 'react'
import '../../styles.css'
import './video.css'

const Video = (props)  => (
    <div className="video__wrapper">
        <div className="video__video-bg">
            <h1 className="video__header">
                More Features.
            </h1>
            <h1 className="video__header">
                More Growth.
            </h1>
            <div className="video__video-placeholder">
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/hnuXv_VgfEc" title="llama-video" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
            {/* <div onClick={props.scrollFuncContact} className="video__video-cta"> */}
            <a href="https://shops.llama.app/?create_user=true" className="video__video-cta">Start your free trial</a>
        </div>
    </div>
)

export default Video