import React, { Component } from 'react'
import '../../styles.css'
import './form.css'
import axios from 'axios';
import successBadge from '../../images/form-success-badge.png'
import secureGDPR from '../../images/secure-feats-01.svg'
import secureUptime from '../../images/secure-feats-02.svg'
import secureAWS from '../../images/secure-feats-03.svg'

const errorMessages = {
    "Member Exists": "You have already signed up for our mailing list.",
    "Invalid Resource": "Please provide a valid email address."
}

const SUCCESS_MESSAGE = "Success! Your form has been submitted.";

const validationFunctions = {
    EMAIL: (email) => {
        const validEmail = /^.+@.+\..+$/;
        return validEmail.test(email);
    },
    DOMAIN: (domain) => {
        const validDomain = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
        return validDomain.test(domain);
    },
    PHONE: (phone) => {
        const validPhone = /\(*\d{3}\)*( |-)*\d{3}( |-)*\d{4}/;
        return validPhone.test(phone);
    }
}

class Form extends Component {

    constructor(props) {
        super(props)
        this.state = {
            formSelected: "brand",
            message: "",
            formSuccess: false,

            isValid: {
                EMAIL: true,
                FNAME: true,
                LNAME: true,
                EXPERIENCE: true,
                GOAL: true,
                DOMAIN: true,
                PHONE: true,
            },

            user: {
                EMAIL: "",
                FNAME: "",
                LNAME: "",
                PHONE: "",
            },

            brandForm: {
                EXPERIENCE: "None",
                GOAL: "Lower cost",
                DOMAIN: "",
                FORMTYPE: "brand",
            },

            ambassadorForm: {
                FBNAME: "",
                IGNAME: "",
                TWNAME: "",
                YTNAME: "",
                FORMTYPE: "ambassador",
            },

            agencyForm: {
                COMPANY: "",
                CONTACT: "",
                COMMENTS: "",
                FORMTYPE: "agency",
            },
        };

        this.selectBrandForm = this.selectBrandForm.bind(this);
        this.selectAmbassadorForm = this.selectAmbassadorForm.bind(this);
        this.selectAgencyForm = this.selectAgencyForm.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateField = this.validateField.bind(this);
    }

    selectBrandForm() {
        this.setState({ 
            formSelected: "brand",
            message: "",
        });
    }

    selectAmbassadorForm() {
        this.setState({ 
            formSelected: "ambassador",
            message: "", 
        });
    }

    selectAgencyForm() {
        this.setState({ 
            formSelected: "agency",
            message: "", 
        });
    }

    handleInputChange(event) {
        const formNames = {
            brand: "brandForm",
            ambassador: "ambassadorForm",
            agency: "agencyForm"
        }
        //formName obj 

        const form = formNames[this.state.formSelected];

        //const user = this.state.user;

        const target = event.target;
        const name = target.name;
        const value = event.target.value;

        const userFields = Object.keys(this.state.user);
        
        if (userFields.includes(name)) {
            const userData = { ...this.state.user, [name]: value };
            this.setState({ user: userData });
            return;
        }

        const newData = { ...this.state[form], [name]: value }
        this.setState({ [form]: newData });

    }

    validateField(event) {
        const { name, value } = event.target;
        if (!name) return;

        const defaultValidation = (value) => {
            return value && value !== "" ? true : false;
        }

        const validate = validationFunctions[name]
            ? validationFunctions[name]
            : defaultValidation

        const valid = validate(value);
        this.setState({ isValid: { ...this.state.isValid, [name]: valid } })
    }

    handleSubmit(event) {
        event.preventDefault();

        // eslint-disable-next-line
        if (process.env.NODE_ENV === `production` && typeof gtag === `function`) {
            // eslint-disable-next-line
            gtag('event', 'Submit', { 'event_category': 'Lead Forms', 'event_label': 'Merchant Lead Gen Form', 'value': 5 });
        }

        const url = 'https://dnrl4lfybh.execute-api.us-east-1.amazonaws.com/dev/hello';
        const APICall = (data) => {
            axios.post(url, data)
                .then((response) => {
                    console.log("RESPONSE", response);
                    if (response.data.status === "success") {
                        this.setState({ message: SUCCESS_MESSAGE });
                        this.setState({ formSuccess: true});
                        return;
                    }

                    else {
                        const message = errorMessages[response.data.message.title] || "Sorry. There was a problem submitting the form.";
                        this.setState({ message: message });
                    }
                })
                .catch((error) => {
                    console.log("ERROR", error);
                    this.setState({ message: "Sorry. There was a problem submitting the form." });
                })
        }

        switch (this.state.formSelected) {
            case "brand":
                let data = {...this.state.brandForm, ...this.state.user };
                return APICall(data);
            case "ambassador":
                data = {...this.state.ambassadorForm, ...this.state.user };
                return APICall(data);
            case "agency":
                data = {...this.state.agencyForm, ...this.state.user };
                return APICall(data);
        }
    }

    render() {

        const formFeedback = (
            <p className={this.state.message === SUCCESS_MESSAGE ? "form__submit-success" : "form__submit-failure"}>
                {this.state.message}
            </p>
        )

        if (this.state.formSuccess === true) {
            return (
                <div className="form__wrapper" ref={this.props.contactScroll}>
                    <div className="form__header-wrapper">
                        <h1 className="form__header">
                            Thank You
                        </h1>
                        <div className="form__success-badge">
                            <p className="form__success-title">
                                Thank You!
                            </p>
                            <p className="form__success-paragraph">
                                Your form has been submitted.
                            </p>
                            <img alt="Success Badge" src={successBadge} className="form__success-badge-img" />
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="form__wrapper" ref={this.props.contactScroll}>
                <div className="form__header-wrapper">
                    <h1 className="form__header">
                        Ready for a radical change?
                    </h1>
                    <p className="form__paragraph">
                        Ready to let Llama make a difference in your affiliate network and want more information? Select the option that best suits the needs of your business, 
                        fill out the form and we'll help you get your Llama account set up.
                    </p>
                </div>

                <div className="form__form-select">
                    <div className="form__select-span">
                        <h3 className="form__form-select-header">
                            I am:
                        </h3>
                        {this.state.formSelected === "brand" &&
                            <h3 className="form__form-selected">
                                A Brand
                            </h3>}
                        {this.state.formSelected === "ambassador" &&
                            <h3 className="form__form-selected">
                                An Ambassador
                            </h3>}
                        {this.state.formSelected === "agency" &&
                            <h3 className="form__form-selected">
                                An Agency
                            </h3>}
                    </div>

                    <div className="form__form-select-row">
                        <div onClick={this.selectBrandForm} className={this.state.formSelected === "brand" ? "form__form-type--active" : "form__form-type"}>
                            Brand
                        </div>
                        <div onClick={this.selectAmbassadorForm} className={this.state.formSelected === "ambassador" ? "form__form-type--active" : "form__form-type"}>
                            Ambassador
                        </div>
                        <div onClick={this.selectAgencyForm} className={this.state.formSelected === "agency" ? "form__form-type--active" : "form__form-type"}>
                            Agency
                        </div>
                    </div>
                </div>

                {this.state.formSelected === "brand" &&
                    <div className="form--form-container">
                        <form className="form--question-form" onSubmit={this.handleSubmit} method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" noValidate>
                            <label htmlFor="mce-EMAIL">Email Address <span className="asterisk">*</span></label>
                            <input
                                type="email"
                                value={this.state.user.EMAIL}
                                onChange={this.handleInputChange}
                                onBlur={this.validateField}
                                name="EMAIL"
                                className={this.state.isValid.EMAIL ? "form--form-item" : "form--form-item form--form-item--invalid"}
                                id="mce-EMAIL"
                            />

                            <label htmlFor="mce-FNAME">First Name <span className="asterisk">*</span></label>
                            <input
                                type="text"
                                value={this.state.user.FNAME}
                                onChange={this.handleInputChange}
                                onBlur={this.validateField}
                                name="FNAME"
                                className={this.state.isValid.FNAME ? "form--form-item" : "form--form-item form--form-item--invalid"}
                                id="mce-FNAME"
                            />

                            <label htmlFor="mce-LNAME">Last Name  <span className="asterisk">*</span></label>
                            <input
                                type="text"
                                value={this.state.user.LNAME}
                                onChange={this.handleInputChange}
                                onBlur={this.validateField}
                                name="LNAME"
                                className={this.state.isValid.LNAME ? "form--form-item" : "form--form-item form--form-item--invalid"}
                                id="mce-LNAME"
                            />

                            <label htmlFor="mce-PHONE">Phone Number  <span className="asterisk">*</span></label>
                            <input
                                type="text"
                                value={this.state.user.PHONE}
                                onChange={this.handleInputChange}
                                onBlur={this.validateField}
                                name="PHONE"
                                className={this.state.isValid.PHONE ? "form--form-item" : "form--form-item form--form-item--invalid"}
                                id="mce-PHONE"
                            />

                            <label htmlFor="mce-DOMAIN">Domain *</label>
                            <input
                                type="text"
                                value={this.state.brandForm.DOMAIN}
                                onChange={this.handleInputChange}
                                onBlur={this.validateField}
                                name="DOMAIN"
                                className={this.state.isValid.DOMAIN ? "form--form-item" : "form--form-item form--form-item--invalid"}
                                id="mce-DOMAIN"
                            />

                            <label htmlFor="mce-EXPERIENCE">Experience with Influencer/Affiliate Platforms</label>
                            <select
                                form="mc-embedded-subscribe-form"
                                name="EXPERIENCE"
                                value={this.state.brandForm.EXPERIENCE}
                                onChange={this.handleInputChange}
                                onBlur={this.validateField}
                                className="form--select-box"
                                id="mce-EXPERIENCE">
                                <option value="None">None</option>
                                <option value="Refersion">Refersion</option>
                                <option value="LeadDyno">LeadDyno</option>
                                <option value="Affiliatly">Affiliatly</option>
                                <option value="Other">Other</option>
                            </select>

                            <label htmlFor="mce-GOAL">Your Goal for Using Llama</label>
                            <select
                                name="GOAL"
                                value={this.state.brandForm.GOAL}
                                onChange={this.handleInputChange}
                                onBlur={this.validateField}
                                className="form--select-box"
                                id="mce-GOAL">
                                <option value="Lower cost">Lower cost</option>
                                <option value="Need easier to use platform">Need easier to use platform</option>
                                <option value="Need better support">Need better support</option>
                                <option value="Looking for better quality influencers">Looking for better quality influencers</option>
                            </select>

                            <p className="form__form-caption">
                                * Required
                            </p>
                            {formFeedback}
                            <input name="subscribe" type="submit" className="form--form-submit" id="mc-embedded-subscribe" value="Submit" />
                        </form>
                    </div>}
                {/* END BRAND FORM */}

                {this.state.formSelected === "ambassador" &&
                    <div className="form--form-container">
                        <form className="form--question-form" onSubmit={this.handleSubmit} method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" noValidate>
                            <label htmlFor="mce-EMAIL">Email Address <span className="asterisk">*</span></label>
                            <input
                                type="email"
                                value={this.state.user.EMAIL}
                                onChange={this.handleInputChange}
                                onBlur={this.validateField}
                                name="EMAIL"
                                className={this.state.isValid.EMAIL ? "form--form-item" : "form--form-item form--form-item--invalid"}
                                id="mce-EMAIL"
                            />

                            <label htmlFor="mce-FNAME">First Name <span className="asterisk">*</span></label>
                            <input
                                type="text"
                                value={this.state.user.FNAME}
                                onChange={this.handleInputChange}
                                onBlur={this.validateField}
                                name="FNAME"
                                className={this.state.isValid.FNAME ? "form--form-item" : "form--form-item form--form-item--invalid"}
                                id="mce-FNAME"
                            />

                            <label htmlFor="mce-LNAME">Last Name  <span className="asterisk">*</span></label>
                            <input
                                type="text"
                                value={this.state.user.LNAME}
                                onChange={this.handleInputChange}
                                onBlur={this.validateField}
                                name="LNAME"
                                className={this.state.isValid.LNAME ? "form--form-item" : "form--form-item form--form-item--invalid"}
                                id="mce-LNAME"
                            />

                            <label htmlFor="mce-PHONE">Phone Number  <span className="asterisk">*</span></label>
                            <input
                                type="text"
                                value={this.state.user.PHONE}
                                onChange={this.handleInputChange}
                                onBlur={this.validateField}
                                name="PHONE"
                                className={this.state.isValid.PHONE ? "form--form-item" : "form--form-item form--form-item--invalid"}
                                id="mce-PHONE"
                            />

                            <h4 className="form__section-title">
                                Social Media
                            </h4>

                            <div className="form--input-row">
                                <div className="form--input-fit">
                                    <label htmlFor="mce-FBNAME">Facebook</label>
                                    <input
                                        type="text"
                                        value={this.state.ambassadorForm.FBNAME}
                                        onChange={this.handleInputChange}
                                        onBlur={this.validateField}
                                        name="FBNAME"
                                        className="form--form-item"
                                        id="mce-FBNAME"
                                        placeholder="/leollama"
                                    />
                                </div>

                                <div className="form--input-fit">
                                    <label htmlFor="mce-IGNAME">Instagram</label>
                                    <input
                                        type="text"
                                        value={this.state.ambassadorForm.IGNAME}
                                        onChange={this.handleInputChange}
                                        onBlur={this.validateField}
                                        name="IGNAME"
                                        className="form--form-item"
                                        id="mce-IGNAME"
                                        placeholder="@leollama"
                                    />
                                </div>
                            </div>

                            <div className="form--input-row">
                                <div className="form--input-fit">
                                    <label htmlFor="mce-IGNAME">Twitter</label>
                                    <input
                                        type="text"
                                        value={this.state.ambassadorForm.TWNAME}
                                        onChange={this.handleInputChange}
                                        onBlur={this.validateField}
                                        name="TWNAME"
                                        className="form--form-item"
                                        id="mce-TWNAME"
                                        placeholder="@leollama"
                                    />
                                </div>

                                <div className="form--input-fit">
                                    <label htmlFor="mce-YTNAME">YouTube</label>
                                    <input
                                        type="text"
                                        value={this.state.ambassadorForm.YTNAME}
                                        onChange={this.handleInputChange}
                                        onBlur={this.validateField}
                                        name="YTNAME"
                                        className="form--form-item"
                                        id="mce-YTNAME"
                                        placeholder="/leollama"
                                    />
                                </div>
                            </div>
                            <p className="form__form-caption">
                                * Required
                            </p>
                            {formFeedback}
                            <input name="subscribe" type="submit" className="form--form-submit" id="mc-embedded-subscribe" value="Submit" />
                        </form>
                    </div>}

                {/* END AMBASSADOR FORM */}

                {this.state.formSelected === "agency" &&
                    <div className="form--form-container">
                        <form className="form--question-form" onSubmit={this.handleSubmit} method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" noValidate>
                            <label htmlFor="mce-EMAIL">Email Address <span className="asterisk">*</span></label>
                            <input
                                type="email"
                                value={this.state.user.EMAIL}
                                onChange={this.handleInputChange}
                                onBlur={this.validateField}
                                name="EMAIL"
                                className={this.state.isValid.EMAIL ? "form--form-item" : "form--form-item form--form-item--invalid"}
                                id="mce-EMAIL"
                            />

                            <label htmlFor="mce-FNAME">First Name <span className="asterisk">*</span></label>
                            <input
                                type="text"
                                value={this.state.user.FNAME}
                                onChange={this.handleInputChange}
                                onBlur={this.validateField}
                                name="FNAME"
                                className={this.state.isValid.FNAME ? "form--form-item" : "form--form-item form--form-item--invalid"}
                                id="mce-FNAME"
                            />

                            <label htmlFor="mce-LNAME">Last Name  <span className="asterisk">*</span></label>
                            <input
                                type="text"
                                value={this.state.user.LNAME}
                                onChange={this.handleInputChange}
                                onBlur={this.validateField}
                                name="LNAME"
                                className={this.state.isValid.LNAME ? "form--form-item" : "form--form-item form--form-item--invalid"}
                                id="mce-LNAME"
                            />

                            <label htmlFor="mce-PHONE">Phone Number  <span className="asterisk">*</span></label>
                            <input
                                type="text"
                                value={this.state.user.PHONE}
                                onChange={this.handleInputChange}
                                onBlur={this.validateField}
                                name="PHONE"
                                className={this.state.isValid.PHONE ? "form--form-item" : "form--form-item form--form-item--invalid"}
                                id="mce-PHONE"
                            />

                            <label htmlFor="mce-COMPANY">Company Name</label>
                            <input
                                type="text"
                                value={this.state.agencyForm.COMPANY}
                                onChange={this.handleInputChange}
                                onBlur={this.validateField}
                                name="COMPANY"
                                className="form--form-item"
                                id="mce-COMPANY"
                            />

                            <label htmlFor="mce-CONTACT">Preferred Contact</label>
                            <input
                                type="text"
                                value={this.state.agencyForm.CONTACT}
                                onChange={this.handleInputChange}
                                onBlur={this.validateField}
                                name="CONTACT"
                                className="form--form-item"
                                id="mce-CONTACT"
                            />

                            <label htmlFor="mce-COMMENTS">How Can We Better Serve You?</label>
                            <textarea
                                type="textarea"
                                value={this.state.agencyForm.COMMENTS}
                                onChange={this.handleInputChange}
                                onBlur={this.validateField}
                                name="COMMENTS"
                                className="form--form-textarea"
                                id="mce-COMMENTS"
                                rows="3"
                            />
                            <p className="form__form-caption">
                                * Required
                            </p>
                            {formFeedback}
                            <input name="subscribe" type="submit" className="form--form-submit" id="mc-embedded-subscribe" value="Submit" />
                        </form>
                    </div>}
                {/* END AGENCY FORM */}
                <div className="form__secure-form">
                    <h1 className="form__subheader">
                        Your data is safe with us
                    </h1>
                    <p className="form__secure-caption">
                        Llama is a GDPR compliant, reliable solution you can depend on.
                    </p>
                    <div className="form__secure-row">
                        <div className="form__secure-item">
                            <img alt="GDPR Compliant" src={secureGDPR} className="form__secure-img" />
                            <h3 className="form__secure-title">
                                GDPR
                            </h3>
                            <p className="form__secure-paragraph">
                                We adhere to the General Data Protection
                                Regulation, giving you improved security
                                and management over your personal data.
                            </p>
                        </div>
                        <div className="form__secure-item">
                            <img alt="99.9% Uptime" src={secureUptime} className="form__secure-img" />
                            <h3 className="form__secure-title">
                                99.9%+ uptime
                            </h3>
                            <p className="form__secure-paragraph">
                                Our efficient, reliable platform is available
                                anytime you need it.
                            </p>
                        </div>
                        <div className="form__secure-item">
                            <img alt="AWS Infrastructure" src={secureAWS} className="form__secure-img" />
                            <h3 className="form__secure-title">
                                AWS infrastructure
                            </h3>
                            <p className="form__secure-paragraph">
                                Llama runs on the Amazon Web
                                Services Cloud, bringing speed, flexibility,
                                and ease-of-use to your platform.
                            </p>
                        </div>
                    </div>
                </div>
                {/* END SECURE SECTION */}
            </div>
        )
    }
}

export default Form