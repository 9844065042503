import React from 'react'
import '../../styles.css'
import './hero.css'

import logo from '../../images/llama-app-logo.svg'
import desktopMock from '../../images/llama-desktop.png'
import geo01 from '../../images/geometric-bg-01.svg'
import llamaBG from '../../images/llama-bg-01.png'
import llamaBGLG from '../../images/llama-bg-01.png'
import llamaBGT from '../../images/llama-bg-01-tablet.png'
import llamaBGM from '../../images/llama-bg-01-mobile.png'
import ambassador from '../../images/ambassador-icon.svg'
import merchant from '../../images/merchant-icon.svg'
import userIcon from '../../images/user-login-icon.svg'

const Hero = (props) => (
    <div className="hero__wrapper">
        <div className="hero__geometric-bg">
            <img alt="Vector Background" src={geo01} className="geometric-patt-01" />
        </div>
        <div className="hero__main-bg-test">
            <img alt="Llama Background" src={llamaBGLG} className="hero-bg-lg" />
            <img alt="Llama Background" src={llamaBG} className="hero-bg-01" />
            <img alt="Llama Background" src={llamaBGT} className="hero-bg-01-tab" />
            <img alt="Llama Background" src={llamaBGM} className="hero-bg-01-mobile" />
        </div>
        <div className="header__wrapper">
            <div className="header__wrapper-flex">
                <div className="header__wrapper-left">
                    <img alt="llama.app" src={logo} className="header__logo-wordmark" />
                    <a href="https://llama.app/ambassadors" className="header__nav-item--link">
                        <div className="header__nav-item">Ambassadors</div>
                    </a>
                    <div onClick={props.scrollFunc} className="header__nav-item">Features</div>
                    <div onClick={props.scrollFuncPlans} className="header__nav-item">Pricing</div>
                    <div onClick={props.scrollFuncContact} className="header__nav-item">Contact</div>
                </div>
                <div className="header__wrapper-right">
                    <ul className={props.isDropdownOpen? "header__wrapping-list header__wrapping-list--open" : "header__wrapping-list"}>
                        <li onClick={props.handleDropdown} className="header__wrapping-list-item">
                            <div className={props.isDropdownOpen ? "header__login-wrap header__login-wrap--open" : "header__login-wrap"}>
                                <span>Login</span>
                                <span className={props.isDropdownOpen? "header__nav-icon-bg header__nav-icon-bg--open" : "header__nav-icon-bg"}></span>
                            </div>
                            <ul className={props.isDropdownOpen ? "header__hidden-list header__hidden-list--open" : "header__hidden-list"}>
                                <li className="header__hidden-list-item">
                                    <img src={merchant} className="header__dropdown-icon-merchant" />
                                    <a href="https://shops.llama.app">Merchants</a>
                                </li>
                                <li className="header__hidden-list-item">
                                    <img src={ambassador} className="header__dropdown-icon" />
                                    <a href="https://ambassadors.llama.app">Ambassadors</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <a href="https://shops.llama.app/?create_user=true" className="header__nav-cta">Start your free trial</a>
                </div>
                {/* End header__wrapper-right */}
            </div>
            {/* End header__wrapper-flex */}
        </div>
        {/* End header__wrapper */}

        <div className="header__mobile-header-wrapper">
            <img alt="llama.app" src={logo} className="header__logo-wordmark-mobile" />
            <div onClick={props.toggleMobile} className="header__nav-group--ham-menu">
                <div className={props.isMobile === true ? "header__nav-group--menu-item menu-item-close01" : "header__nav-group--menu-item"}></div>
                <div className={props.isMobile === true ? "header__nav-group--menu-item menu-item-close02" : "header__nav-group--menu-item"}></div>
                <div className={props.isMobile === true ? "header__nav-group--menu-item menu-item-close03" : "header__nav-group--menu-item"}></div>
            </div>
        </div>

        {props.isMobile && (
            <div className={props.isMobile === true ? "header__nav-group--mobile-menu header__nav-group--Mobile": "header__nav-group--mobile-menu"}>
                <div className="header__nav-links-mobile">
                    <a href="https://llama.app/ambassadors" className="header__nav-item--link">
                        <div className="header__nav-item-mobile">Ambassadors</div>
                    </a>
                    <div onClick={props.scrollFunc} className="header__nav-item-mobile">Features</div>
                    <div onClick={props.scrollFuncPlans} className="header__nav-item-mobile">Pricing</div>
                    <div onClick={props.scrollFuncContact} className="header__nav-item-mobile">Contact</div>
                </div>
                <div className="header__nav-logins-mobile">
                    <div className="header__nav-item-mobile">
                        <span>Login</span>
                        <img src={userIcon} className="header__nav-user-mobile" />
                    </div>
                    <div className="header__nav-item-mobile header__nav-item-mobile--sub">
                        <a href="https://shops.llama.app" className="header__mobile-link">Merchants</a>
                    </div>
                    <div className="header__nav-item-mobile header__nav-item-mobile--sub">
                        <a href="https://ambassadors.llama.app" className="header__mobile-link">Ambassadors</a>
                    </div>
                    <div className="header__nav-cta"><a href="https://shops.llama.app/?create_user=true">Start your free trial</a></div>
                </div>
            </div>
        )}

        <div className="hero__flex-row">
            <div className="hero__main-content">
                <h1 className="hero__main-header">
                    Affiliate Marketing is Broken!
                </h1>
                <p className="hero__main-paragraph">
                    Time to face facts - the industry is outgrowing one-and-done flat fee models. Vendors aren’t happy with the ROI and ambassadors aren’t enthused about endlessly spamming their audiences with promo after promo. The market is speaking. It’s time for a change.
                </p>
                <div className="hero__main-cta">
                    <a href="https://shops.llama.app/?create_user=true">Start your free trial</a>
                </div>
            </div>
            <div className="hero__mockup-container">
                <img alt="LLAMA.app on Desktop" src={desktopMock} className="hero__desktop-mockup" />
            </div>
        </div>

        <div className="hero__secondary-wrapper">
            <div className="hero__secondary-content">
                <div className="hero__secondary-content-wrapper">
                    <h1 className="hero__secondary-header">
                        Affiliate Marketing, Evolved
                    </h1>
                    <div className="hero__sec-par-wrapper">
                        <h2 className="hero__secondary-paragraph">
                            The old school commission model misaligns the respective goals of ambassadors and vendors. Llama streamlines things by synergizing everyone’s goals.
                        </h2>
                    </div>
                    <div className="hero__sec-par-wrapper2">
                        <h2 className="hero__secondary-paragraph">
                            How do we pull it off? Our Long-Term Value fee model gives ambassadors a small kickback on sales from customers they send your way.
                        </h2>
                    </div>
                    <div className="hero__sec-par-wrapper2">
                        <h2 className="hero__secondary-paragraph">
                        The more these customers buy from you, the more kickbacks your partners get. They get rewarded for promoting your brand more effectively and you get more sales. Everyone wins.
                        </h2>
                    </div>
                </div>
            </div>
            {/* End hero__secondary-content */}
        </div>
        {/* End hero__secondary-wrapper */}
{/* End hero__wrapper */}
</div>
)

export default Hero